import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'

const Events = ({events}) => {
  var count = 0;
  return (
  <div className='events p20 pt0'>
    <div className='grid'>
      {events?.map((event, index) => {
        count++; if(count > 2) { count = 1; var space = <div/>;} 
        return (
          <>
          {space}
          <Link to={'/news/'+event.node.slug} key={index} className='single--event link mb40'>
            <div className='bg-grey mb5 pos-rel'>
              <GatsbyImage key={index} image={event.node.gallery[0]?.gatsbyImageData} alt='Gallery Thumbnail' />
            </div> 
            <p className='m0 aime mb5 m-m0'>{event.node.name}</p>
            <span className='grey f-small'>{event.node.date.publishedAt}</span>
          </Link>
          </>
        )
      })}
    </div>
  </div>
  )
}

export default Events
