import * as React from "react"
import Layout from "../components/layout"
import Events from "../components/events"
import Seo from "../components/seo"
import { graphql } from 'gatsby'

const EventsPage = ({data}) => {
  return (
  <Layout>
    <Events events={data.events.edges} />
  </Layout>
  )
} 


export const Head = () => <Seo title="Events" />

export default EventsPage


export const query = graphql`
query EventsQuery {
  events:allDatoCmsEvent(sort: {fields: meta___publishedAt, order: DESC}) {
    edges {
      node {
        name
        slug
        introduction
        date:meta {
          publishedAt(formatString: "D MMMM YYYY")
        }
        gallery {
          gatsbyImageData
        }
      }
    }
  }
}
`